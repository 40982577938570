<template>
  <div class="creations" :class="{ 'text-center align-items-center mt-3': !isVisible && isEmpty(creations) }">
    <div class="wrap d-flex align-items-center" v-if="isVisible">
      <div class="folders" :class="{ 'mt-3': isDesktopSize }">
        <ion-select
          v-if="!loading"
          mode="md"
          class="c-select font-select bg-white w-100"
          :value="selectedFolder"
          @ionChange="onChange"
        >
          <ion-select-option value="">All Creations</ion-select-option>

          <ion-select-option v-for="(folder, index) of folders" :key="index" :value="folder.id"
            >{{ folder.name }}
          </ion-select-option>
        </ion-select>
        <div v-else-if="loading" class="d-flex mt-n1">
          <ion-skeleton-text v-for="(_, index) of [1, 2]" :key="index" class="tag-loading mr-2" animated />
        </div>
      </div>
    </div>

    <div
      v-if="creations?.length && !contentLoading"
      class="creations px-2"
      :class="{ 'd-flex flex-wrap c-mob': !isDesktopSize }"
    >
      <div
        v-for="(creation, index) of creations"
        :key="index"
        class="one-creation d-flex align-items-center mt-3"
        :class="{ 'mobile flex-column justify-content-center': !isDesktopSize }"
        @click="goToCreation(creation)"
      >
        <ProfileRing
          :image="profileRingIcon(creation)"
          :borderWidth="ringWidth"
          :ringTop="ringTopAlignment"
          :ringLeft="ringLeftAlignment"
          class="position-absolute"
          v-if="isVisible"
        />
        <div
          class="image d-flex align-items-center justify-content-center"
          :class="{
            rounded: creation.allow_roleplay_req === true || creation.allow_roleplay_req === false,
            'mr-2': isDesktopSize,
          }"
        >
          <img
            loading="lazy"
            v-character-card-image
            :src="resizeUpload(creation.info?.cropProfilePicture || creation.info?.profilePicture || creation.info?.cover || creation?.cover_image, '80x80')"
          />
        </div>
        <div class="name" :class="{ 'text-center': !isDesktopSize }" v-if="isVisible">
          {{ creation.info?.name || creation.name }} <span v-if="creation?.privacy === 'M'">🔒&nbsp;</span
          ><span v-if="creation?.privacy === 'U'">🔗&nbsp;</span>
        </div>
      </div>
    </div>
    <div v-else-if="contentLoading" class="mt-3">
      <ion-skeleton-text v-for="(_, index) of [1, 2, 3, 4]" :key="index" class="mb-3" animated />
    </div>
    <div v-else>
      <div v-if="selectedFolder">Empty folder</div>
      <div class="d-flex align-items-center flex-column justify-content-center" v-else>
        <div class="no-data">No creations yet</div>
        <router-link class="mt-n1" :to="{ name: 'character-creator' }" v-if="isVisible">
          <ion-button>Create character</ion-button>
        </router-link>
      </div>
    </div>
    <div v-if="!contentLoading && count > 9" class="d-flex justify-content-end mt-3">
      <ion-button fill="clear" @click="seeAll"> See all </ion-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';
import {
  getCharactersForCollection,
  getMyCreationsCharactersForFolder,
  getMyCreationsWorldsForFolder,
  getWorldsForCollection,
} from '@/shared/actions/collections';
import { Character, WorldsInfo } from '@/shared/types/static-types';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import { mainStore } from '@/shared/pinia-store/main';
import { resizeUpload } from '@/shared/utils/upload';
import ProfileRing from '@/shared/components/ProfileRing.vue';
import { getMyCreationsUserCharacters, getUserCharacters } from '@/shared/actions/characters';
import { getMyCreationsUserStoryWorlds, getUserStoryWorld } from '@/shared/actions/worlds';

const props = defineProps({
  ringWidth: {
    type: Number,
  },
  isVisible: {
    type: Boolean,
  },
  ringTopAlignment: {
    type: Number,
  },
  ringLeftAlignment: {
    type: Number,
  },
  folders: {
    type: Array,
    default: () => [],
  },
  selectedHideFolder: {
    type: String,
  },
});

const loading = ref(false);
const contentLoading = ref(false);
const folders: any = toRef(props, 'folders');
const isVisible = toRef(props, 'isVisible');
const allCreations: any = ref<(WorldsInfo | Character)[]>();
const creations = ref<(WorldsInfo | Character)[]>();
const selectedFolder = ref<string>('');
const count = ref(0);
const emits = defineEmits(['selected']);
const { isDesktopSize } = useWindowSize();
const selectedHideFolder: any = toRef(props, 'selectedHideFolder');

watch(selectedHideFolder, () => {
  selectedFolder.value = selectedHideFolder.value;
});
const onChange = (e: CustomEvent) => {
  selectedFolder.value = e.detail.value;
};

const { user, isPrfRingUpdated } = authStore();
const router = useRouter();

const seeAll = () => {
  router.push({ name: 'profile', params: { username: user.value?.username }, query: { creations: 1 } });
};

const defaultPageRoute = computed(() => {
  try {
    const router = useRouter();
    return router.currentRoute.value.name;
  } catch (error) {
    return '';
  }
});

const { dark: isDark } = mainStore();

const { customizeCharacter, currentTemplate, template } = characterStylizeStore();

const customizeChar = computed(() => {
  return customizeCharacter.value;
});

const getBackground = computed(() => {
  if (
    ['character-profile-new', 'character-profile-draft-new', 'character-stylize', 'character-draft-stylize'].includes(
      defaultPageRoute.value as string
    )
  ) {
    return isEmpty(get(customizeChar.value, 'template_applied')) &&
      isDark.value &&
      ['character-profile-draft-new', 'character-profile-new'].includes(defaultPageRoute.value as string)
      ? '#010123 !important'
      : `${get(currentTemplate.value, 'data.theme.backgrounds.page')} !important`;
  }
});

const populateAllCreations = async () => {
  contentLoading.value = true;
  if (!user.value?.username) return;

  const [userCharacters, userWorlds] = await Promise.all([
    getMyCreationsUserCharacters(user.value?.id),
    getMyCreationsUserStoryWorlds(user.value?.id),
  ]);

  const preMerged = [...(userWorlds.value?.results || []), ...(userCharacters.value?.results || [])];

  count.value = preMerged.length;

  const merged = preMerged.sort((a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime()).slice(0, 9);

  allCreations.value = merged;
  contentLoading.value = false;
};

const populateFolderCreations = async () => {
  const selectedFolderData = folders.value.find((folder: any) => folder.id === selectedFolder.value);

  if (!selectedFolderData) return;

  const tasks = [selectedFolderData].reduce((tot, acc) => {
    return [...tot, getMyCreationsCharactersForFolder(acc.id), getMyCreationsWorldsForFolder(acc.id)];
  }, [] as any[]);

  const data = await Promise.all(tasks);

  const merged = flatten(data.map((item) => item.value?.results || [])).sort(
    (a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime()
  );

  return merged.splice(0, 9);
};
const fetchCreationsContent = async () => {
  try {
    loading.value = true;
    await populateAllCreations();
    loadCreationContent();
    loading.value = false;
  } catch (error) {}
};
onMounted(() => {
  fetchCreationsContent();
});

watch(isPrfRingUpdated, async () => {
  if (isPrfRingUpdated.value) {
    fetchCreationsContent();
  }
});
const getCreationImg = computed(() => {
  return isVisible.value ? '36px' : '45px';
});
const loadCreationContent = async () => {
  contentLoading.value = true;

  if (!selectedFolder.value) {
    creations.value = allCreations.value;
    contentLoading.value = false;
    return;
  }

  creations.value = await populateFolderCreations();

  contentLoading.value = false;
};

const goToCreation = (creation: WorldsInfo | Character) => {
  if (creation.allow_roleplay_req === true || creation.allow_roleplay_req === false) {
    router.push({ name: 'character-profile-new', params: { slug: creation?.slug } });
    return;
  }

  router.push({ name: 'world-details', params: { slug: creation?.slug } });
};

const profileRingIcon = (creation: any) => {
  const index = allCreations.value?.findIndex((item: any) => item.id === creation.id);

  return get(creation, 'profile_ring.image')
    ? get(creation, 'profile_ring.image')
    : get(allCreations.value[index], 'profile_ring.image');
};

watch(selectedFolder, () => {
  loadCreationContent();
  emits('selected', selectedFolder.value);
});
</script>

<style scoped lang="sass">
.creations
  @media(min-width: 1100px)
    padding: 0px 12px 5px 12px
.c-select
  background: v-bind(getBackground) !important
.c-mob
  grid-gap: 10px
.mobile
  width: 70px !important
  .name
    font-size: 12px !important
    margin-top: 5px
.scroller
  pointer-events: auto
  ::v-deep
    .scroll-content
      padding-bottom: 6px

      width: calc(100% - 10px)
      margin: auto
      display: flex
      &::-webkit-scrollbar
        display: block !important
      &::-webkit-scrollbar
        height: 4px
        width: 4px
      &::-webkit-scrollbar-track
        border-radius: 5px
      &::-webkit-scrollbar-thumb:horizontal
        background: var(--ion-color-primary)
      &::-webkit-scrollbar-thumb:hover
        background: #555
    ion-icon
      color: #FFF !important
    ion-button
      --background: var(--ion-color-primary)
      color: #FFF !important
      --border-radius: 15px !important
      width: 25px !important
      height: 25px !important
      margin: 0 !important
      top: -4px !important
      position: relative
.wrap
  gap: 4px
.scroll-btn
  width: 30px
  height: 30px
ion-button
  margin: 0
.tag-loading
  height: 24px
  border-radius: 12px
  min-width: 88px

.one-creation
  cursor: pointer
  .image
    width: v-bind(getCreationImg)
    height: v-bind(getCreationImg)
    min-width: v-bind(getCreationImg)
    border-radius: 3px
    overflow: hidden
    background: #4A9FC3
    box-shadow: 0px 2px 4px 1px rgba(35,14,51,0.19)
    &.rounded
      border-radius: 120px
    img
      width: 100%
      height: 100%
      object-fit: cover
  font-size: 15px
  font-weight: bold
  .name
    display: -webkit-box !important
    -webkit-line-clamp: 1
    -webkit-box-orient: vertical
    overflow: hidden !important
    text-overflow: ellipsis
    color: rgba(33, 65, 99, 1)
  &.mobile
    .image
      width: 58px
      height: 58px
    .name
      display: none
      font-weight: 400 !important

.folders
  display: flex
  overflow-x: auto
  overflow-y: hidden
  width: 100%


  .folder, .all
    cursor: pointer
    height: 24px
    border-radius: 12px
    border: 2.5px solid rgba(122, 126, 155, 1)
    font-size: 10px
    color: rgba(122, 126, 155, 1)
    padding: 0 10px
    font-weight: bold
    text-align: center
    padding: 0px 10px
    line-height: 20px
    .folder-con
      display: -webkit-box !important
      -webkit-line-clamp: 1
      -webkit-box-orient: vertical
      overflow: hidden !important
      text-overflow: ellipsis
    &.active
      background: var(--ion-color-primary)
      color: #FFF
      border: 2px solid var(--ion-color-primary)
      pointer-events: none
  .folder
    min-width: 88px
  .all
    min-width: 44px

.content
  padding: 12px 24px
</style>
